import React, { Component } from "react";
import Img from "gatsby-image";
import styled, { css } from "styled-components";

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  margin-bottom: 10px;
  width: 100%;

  &:before {
    content: "";
    display: block;
    ${props => css`
      padding-top: ${(1 / props.aspectRatio) * 100}%;
    `}
  }
`;

const StyledImage = styled(Img)`
  transition: ${props => props.theme.transitions.create()};
  transform: scale(1.03);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export default class Image extends Component {
  render() {
    const { fluid } = this.props;

    return (
      <ImageContainer aspectRatio={fluid.aspectRatio}>
        <StyledImage fluid={fluid} style={{ position: "absolute" }} />
      </ImageContainer>
    );
  }
}
