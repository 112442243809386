import React from "react";
import styled from "styled-components";
import * as luxon from "luxon";

import { rhythm } from "../utils/typography";
import YoutubeEmbedVideo from "youtube-embed-video";

const Title = styled.div`
  color: #000;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  line-height: 1.1;
`;

const DateTime = styled.div`
  color: #016597;
  margin-bottom: ${rhythm(0.5)};
`;

const Description = styled.p`
  margin-top: ${rhythm(0.5)};
  color: #000;
`;

export default ({ title, description, videoId, date }) => {
  return (
    <div>
      <Title>{title}</Title>
      <DateTime>
        {luxon.DateTime.fromISO(date)
          .setLocale("es")
          .toFormat("DD")}
      </DateTime>
      <YoutubeEmbedVideo videoId={videoId} suggestions={false} width={"100%"} />
      <Description>{description}</Description>
    </div>
  );
};
