import React, { Component } from "react";
import styled from "styled-components";
import Grid from "@tleef/react-grid";
import showdown from "showdown";

import MediaSoundcloud from "../components/MediaSoundcloud";
import MediaYoutube from "../components/MediaYoutube";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Header from "../components/Header";
import Content from "../components/Content";
import Image from "../components/Image";

import { videos, audios } from "../content/data/coro-de-ninos";
import Footer from "../components/Footer";
import { graphql } from "gatsby";
import Heading from "../components/Heading";
import SideBar from "../components/SideBar";

const converter = new showdown.Converter();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 120px;
`;

const BioContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Bio = styled.div``;

const bio = `El coro de niños de nuestra iglesia está compuesto por niños (as) que van desde la edad preescolar hasta la edad de 12 años. 

Cada domingo este hermoso coro canta al Señor en la reunión general de nuestra iglesia, como así también en todos aquellos servicios especiales que se realizan, alabando a Dios a través de los cánticos e instrumentos de los niños y niñas que pertenecen a él. 

Este coro está dirigido por nuestro hermano Manuel Valdés, el cual junto a la ayuda de nuestras hermanas Gipsy Peralta y María Angélica Jiménez , lleva a cabo esta hermosa labor para Dios. 

Los ensayos se realizan cada domingo a las 16:00 horas en nuestro templo catedral.`;

export default class CoroDeNinosPage extends Component {
  constructor() {
    super();

    this.state = {
      filters: {
        video: true,
        audio: false
      }
    };
  }

  render() {
    const { data } = this.props;

    return (
      <Layout>
        <Seo title="Coro de Niños" keywords={[`iglesia`]} />
        <Container>
          <Header />
          <Content>
            <Inner>
              <Grid container spacing={40}>
                <Grid item xs={12} md={8}>
                  <Heading>Coro de Niños</Heading>
                  <Image fluid={data.profileImage.childImageSharp.fluid} />
                  <BioContainer>
                    <Heading>Sobre Nuestro Coro</Heading>
                    <Bio
                      dangerouslySetInnerHTML={{
                        __html: converter.makeHtml(bio)
                      }}
                    />
                  </BioContainer>
                  {/*<Heading>Multimedia</Heading>*/}
                  {/*<MediaMenu*/}
                  {/*  onChange={(filters) => this.setState({filters})}*/}
                  {/*/>*/}
                  {/*<Grid container>*/}
                  {/*  {*/}
                  {/*    this.renderVideos(this.state.filters.video)*/}
                  {/*  }*/}
                  {/*  {*/}
                  {/*    this.renderAudios(this.state.filters.audio)*/}
                  {/*  }*/}
                  {/*</Grid>*/}
                </Grid>
                <Grid item xs={12} md={4}>
                  <SideBar fixTop={180} fixBottom={140} />
                </Grid>
              </Grid>
            </Inner>
          </Content>
          <Footer />
        </Container>
      </Layout>
    );
  }

  renderVideos(active) {
    const style = {};

    if (!active) {
      style.display = "none";
    }

    return (
      <Grid style={style} container spacing={40}>
        {videos.map(media => {
          switch (media.source) {
            case "youtube":
              return this.renderYoutube(media);
            default:
              return null;
          }
        })}
      </Grid>
    );
  }

  renderAudios(active) {
    const style = {};

    if (!active) {
      style.display = "none";
    }

    return (
      <Grid style={style} container spacing={40}>
        {audios.map(media => {
          switch (media.source) {
            case "soundcloud":
              return this.renderSoundcloud(media);
            default:
              return null;
          }
        })}
      </Grid>
    );
  }

  renderYoutube({ title, date, videoId, description }) {
    return (
      <Grid item xs={12}>
        <MediaYoutube
          title={title}
          date={date}
          videoId={videoId}
          description={description}
        />
      </Grid>
    );
  }

  renderSoundcloud({ title, date, url, description }) {
    return (
      <Grid item xs={12}>
        <MediaSoundcloud
          title={title}
          date={date}
          url={url}
          description={description}
        />
      </Grid>
    );
  }
}

export const query = graphql`
  query CoroDeNinosPageQuery {
    profileImage: file(relativePath: { eq: "DSC07317.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
